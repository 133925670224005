import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useEffect, useRef, useContext, useState } from 'react';
import { styled } from 'linaria/react';
// import { Link } from 'react-router-dom';
import { theme } from '../Theme';

// import t from '@jetshop/intl';

export const FlowboxWrapper = styled('div')`
  width: 100%;
  max-width: 90rem;
  margin: auto;
  padding: 0 20px;
  text-align: center;

  #js-flowbox-feed + a {
    &:hover {
      background: black;
      color: white;
    }
  }
`;

// const FlowboxLink = styled(Link)`
//   display: inline-block;
//   width: auto;
//   height: 40px;
//   line-height: 40px;
//   font-size: 0.8rem;
//   padding: 0 50px;
//   background: none;
//   color: black;
//   border: 1px solid black;
//   text-align: center;
//   text-decoration: none;
//   text-transform: uppercase;
//   transition: all, 0.2s ease;
//   margin-top: 20px;
//   margin-bottom: 40px;
//   &:hover {
//     background: white;
//     color: black;
//   }
// `;

const TagsMenu = styled('div')`
  display: flex;
  margin: 1rem 0;
  justify-content: center;
  flex-wrap: wrap;
`;

const TagButton = styled('button')`
  width: auto;
  border: 0;
  margin: 5px;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  transition: all, 0.2s ease;
  height: 40px;
  appearance: none;
  background: white;
  color: black;
  border: 1px solid black;
  padding: 0 20px;

  &:hover {
    background: ${theme.colors.darkgrey};
    color: white;
    border-color: ${theme.colors.darkgrey};
  }

  &.selected {
    background: ${theme.colors.darkgrey};
    color: white;
    border-color: ${theme.colors.darkgrey};
  }
  &:focus {
    outline: none;
  }
`;

// Supported by FlowboxFilter.
const supportedCultures = [
  'de-DE',
  'da-DK',
  'en-GB',
  'es-ES',
  'fi-FI',
  'fr-FR',
  'nl-NL',
  'nb-NO',
  'pt-PT',
  'sv-SE'
];

const initializeFlow = ({
  method = 'init',
  feedKey,
  elementId,
  productId,
  culture,
  tags
}) => {
  const settings = {
    container: `#${elementId}`,
    key: feedKey,
    locale: supportedCultures.includes(culture)
      ? culture === 'en-GB'
        ? 'en-EU'
        : culture
      : 'nb-NO',
    tags: tags
  };
  if (productId) {
    window.flowbox(method, {
      ...settings,
      productId: productId ? productId.toString() : undefined
    });
  } else {
    window.flowbox(method, settings);
  }
};

const initializeFlowbox = options => {
  if (!window.flowbox) {
    // FlowboxFilter
    (function(d, id) {
      if (!window.flowbox) {
        var f = function() {
          f.q.push(arguments);
        };
        f.q = [];
        window.flowbox = f;
      }
      if (d.getElementById(id)) {
        return;
      }
      var s = d.createElement('script'),
        fjs = d.scripts[d.scripts.length - 1];
      s.id = id;
      s.async = true;
      s.src = ' https://connect.getflowbox.com/flowbox.js';
      fjs.parentNode.insertBefore(s, fjs);
    })(document, 'flowbox-js-embed');
  }
  return initializeFlow(options);
};

const Flowbox = ({
  feedKey,
  elementId = 'js-flowbox-feed',
  productId = null,
  tags = [],
  menuTags = [],
  feed,
  showCta
}) => {
  const { selectedChannel = {} } = useContext(ChannelContext);
  const { culture } = selectedChannel.language;
  const flowBoxElement = useRef();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const translateAll = {
    SV: 'ALLA',
    NO: 'ALLE',
    DK: 'ALLE',
    FI: 'KAIKKI',
    EU: 'ALL',
    NL: 'ALLE'
  };

  if (feed) {
    feedKey = feed;
  }

  useEffect(() => {
    if (flowBoxElement.current) {
      initializeFlowbox({
        feedKey,
        elementId,
        productId,
        culture,
        tags
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedKey, productId, culture]);

  return (
    <FlowboxWrapper className="flowbox-wrapper">
      {menuTags.length > 0 && (
        <TagsMenu>
          {menuTags.map((tag, index) => {
            var value = tag;
            var text = tag;
            if (tag.indexOf('(') > -1) {
              value = tag.split('(')[0];
              text = tag
                .split('(')[1]
                .replace('(', '')
                .replace(')', '');
            }
            return (
              <TagButton
                key={index}
                className={index === selectedIndex ? 'selected' : ''}
                onClick={() => {
                  const tagUpdate =
                    index === selectedIndex ||
                    value === translateAll[selectedChannel?.name]
                      ? []
                      : [value];
                  const newIndex = index === selectedIndex ? null : index;
                  initializeFlow({
                    method: 'update',
                    feedKey,
                    elementId,
                    productId,
                    culture,
                    tags: tagUpdate
                  });
                  setSelectedIndex(newIndex);
                }}
              >
                {text}
              </TagButton>
            );
          })}
        </TagsMenu>
      )}
      <div id={elementId} ref={flowBoxElement} />
    </FlowboxWrapper>
  );
};

export default Flowbox;
