import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import React from 'react';
import {
  filterChildrenByKey,
  setVariableFromProp
} from '../../utils/HelpFunctions';
import UIMaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';

export const HeroWrapper = styled('div')`
  max-width: 80rem;
  width: 80%;
  margin: auto;
  letter-spacing: 0.1px;
  color: ${props => props.color} !important;

  ${theme.below.lg} {
    width: 100%;
  }

  h2 {
    font-size: 43px;
    font-family: 'Times New Roman', Times, serif;
    line-height: 43px;
    letter-spacing: 1px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    ${theme.below.lg} {
      font-size: 32px;
      line-height: 34px;
    }
  }

  h3 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 1px;
  }

  [data-flight-image-children] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
  }

  &.TOP {
    [data-flight-image-children] {
      align-items: flex-start;
    }
  }
  &.MIDDLE {
    [data-flight-image-children] {
      align-items: center;
    }
  }

  &.BOTTOM {
    [data-flight-image-children] {
      align-items: flex-end;
    }
  }

  &.LEFT {
    [data-flight-image-children] {
      justify-content: flex-start;
    }
  }

  &.CENTER {
    [data-flight-image-children] {
      justify-content: center;
    }
  }

  &.RIGHT {
    [data-flight-image-children] {
      justify-content: flex-end;
    }
  }
`;

export const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 25%;
  min-width: 500px;

  ${theme.below.lg} {
    min-width: 100%;
    padding: 0 30px;
  }
`;

export const ButtonRow = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  .button-component {
    margin: 5px;
  }
`;

const IngresslWrapper = styled(UIMaxWidth)`
  max-width: 800px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 80%;

  p {
    font-size: 16px;
    /* line-height: 23px; */
    line-height: 1.5;
    letter-spacing: 0.03rem;
    font-weight: 600;
  }

  ${theme.below.lg} {
    width: 100%;
  }
`;

const JournalTitle = styled('h1')`
  color: #000;
  text-align: center;
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 32px;
  font-family: 'Silk Serif';

  ${theme.below.lg} {
    font-size: 24px;
    line-height: 33px;
    padding: 0 20px;
  }
`;

export const JournalHero = ({
  desktopImageUrl,
  desktopImageAspect,
  mobileImageUrl,
  mobileImageAspect,
  children
}) => {
  const deskImgUrl = setVariableFromProp(desktopImageUrl);
  const deskAspect = setVariableFromProp(desktopImageAspect) ?? '2880:1000';
  const mobImgUrl = setVariableFromProp(mobileImageUrl);
  const mobAspect = setVariableFromProp(mobileImageAspect) ?? '375:500';

  let filteredChildren = filterChildrenByKey(children, 'button', false);
  filteredChildren = filterChildrenByKey(filteredChildren, 'ingress', false);
  const ingressChildren = filterChildrenByKey(children, 'ingress', true);
  const textChildren = filterChildrenByKey(children, 'text', true);

  const title = textChildren[0]?.props?.text?.value;

  const imageSizes = [1 / 4, 1 / 2, 1];

  return (
    <HeroWrapper
      color={
        textChildren[0]?.props?.color?.value
          ? textChildren[0]?.props?.color?.value
          : '#000'
      }
    >
      {title && <JournalTitle>{title}</JournalTitle>}
      <Above breakpoint="lg">
        {matches => (
          <>
            <Image
              cover={true}
              aspect={matches ? deskAspect : mobAspect}
              src={matches ? deskImgUrl : mobImgUrl ?? deskImgUrl}
              sizes={imageSizes}
              critical
            >
              {/* <ContentWrapper>{filteredChildren}</ContentWrapper> */}
            </Image>
            <IngresslWrapper>{ingressChildren}</IngresslWrapper>
          </>
        )}
      </Above>
    </HeroWrapper>
  );
};
