import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import { theme } from './Theme';
import '../globalStyles';
import { FaviconSelector } from './Favicon';
import { VoyadoProvider } from '@jetshop/flight-voyado';
import JournalPage from '../components/ContentPage/JournalPage';
import FindifyProvider from './Findify/Findify';
import { CustomerProvider } from './MyPages/CustomerContext';
import { LipscoreCompanyRating } from './Lipscore/LipscoreCompanyRating';
import { HeaderHeightProvider } from '../hooks/HeaderHeightContext';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />
  }
);

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: <LoadingPage />
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

//Make loadable Journal

const LoadableSearchPage = loadable(
  () => import('./SearchPage/SearchPageFindify'),
  {
    fallback: <LoadingPage />
  }
);
const LoadableSignUpPage = loadable(
  () => import('./Auth/Voyado/VoyadoSignup'),
  {
    fallback: <LoadingPage />
  }
);

const VoyadoLookup = loadable(() => import('./Auth/Voyado/VoyadoLookup'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

function Shop() {
  const { routes } = useShopConfig();
  return (
    <GenericError>
      <HeaderHeightProvider>
        <CustomerProvider>
          <FindifyProvider>
            <ModalProvider>
              <Container>
                <LoadingBar color={theme.colors.loadingBar} />
                <Helmet
                  titleTemplate="%s - Ferner Jacobsen"
                  defaultTitle="Ferner Jacobsen"
                />
                <VoyadoProvider>
                  <ProductListProvider queries={productListQueries}>
                    <Header />
                    <Content>
                      <PaginationProvider defaultProductsPerPage={24}>
                        <Switch>
                          <Route exact path="/">
                            <LoadableStartPage />
                          </Route>
                          <Route path="/favourites">
                            <Favourites />
                          </Route>
                          <Route path="/inspirasjon-og-raad">
                            <JournalPage />
                          </Route>
                          <Route path={routes.search.path}>
                            <LoadableSearchPage />
                          </Route>
                          <Route path={routes.signup.path}>
                            <LoadableSignUpPage />
                          </Route>
                          <Route path={routes.login.path}>
                            <VoyadoLookup />
                          </Route>
                          <Route path={routes.login.path}>
                            <LogInPage />
                          </Route>
                          <Route path={routes.logout.path}>
                            <LogOutPage />
                          </Route>
                          <Route path={routes.stores.path}>
                            <StoreLocator />
                          </Route>
                          <Route path={`${routes.store.path}/:id`}>
                            <Store />
                          </Route>
                          <Route path={routes.tree.path}>
                            <NavTreePage />
                          </Route>
                          <Route path={routes.myPages.path}>
                            <LoadableMyPages />
                          </Route>
                          <Route exact path={routes.forgotPassword.path}>
                            <ForgotPassword />
                          </Route>
                          <Route path={`${routes.resetPassword.path}/:token`}>
                            <ResetPassword />
                          </Route>
                          <Route
                            path="/preview"
                            render={props => (
                              <PreviewRoute
                                productQuery={ProductPreviewQuery}
                                categoryQuery={CategoryPreviewQuery}
                                pageQuery={PagePreviewQuery}
                                productPage={LoadableProductPage}
                                categoryPage={LoadableCategoryPage}
                                contentPage={LoadableContentPage}
                                StartPage={LoadableStartPage}
                                {...props}
                              />
                            )}
                          />
                          <DynamicRoute
                            routeQuery={routeQuery}
                            productPage={LoadableProductPage}
                            categoryPage={LoadableCategoryPage}
                            contentPage={LoadableContentPage}
                            notFoundPage={NotFound}
                            LoadingPage={LoadingPage}
                          />
                        </Switch>
                      </PaginationProvider>
                    </Content>
                    <LipscoreCompanyRating />
                    <Footer />
                  </ProductListProvider>
                </VoyadoProvider>
                <ModalRoot />
                <ScrollRestorationHandler
                  ignoreForRouteTypes={[
                    'sortOrderChange',
                    'filterChange',
                    'paginationChange',
                    'FindifyUpdate'
                  ]}
                />
                <FaviconSelector
                  lightIconPath={'/ferner_lighter_favicon.png'}
                  darkIconPath={'/ferner_favicon.png'}
                />
              </Container>
            </ModalProvider>
          </FindifyProvider>
        </CustomerProvider>
      </HeaderHeightProvider>
    </GenericError>
  );
}

export default Shop;
