// import Gallery from '@jetshop/ui/Gallery/Gallery';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { css, cx } from 'linaria';
import { useSwitchToVariantImage } from '@jetshop/core/hooks/Product';
import { SharedSlider } from '../../Layout/SharedSlider';
import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import loadable from '@loadable/component';

const LoadableGallery = loadable(() => import('@jetshop/ui/Gallery/Gallery'), {
  fallback: <div></div>
});

const wrapper = css`
  display: flex;
  background: #f3f3f3;
  flex-direction: column;
  .image-gallery-thumbnails {
    justify-content: flex-start !important;
    padding-top: 2rem !important;
  }

  .image-gallery-thumbnails-container {
    display: flex;
    flex-direction: column;
  }
  .image-gallery-thumbnail {
    transition: none;
    &.active {
      border: none;
    }
  }

  .image-gallery-thumbnail-inner {
    transition: transform 0.3s;
    :hover {
      transform: scale(1.07);
    }
  }

  a {
    margin-bottom: 5px;
    width: 75px;
  }

  .image-gallery-content
    .image-gallery-slide-wrapper
    .image-gallery-swipe
    .image-gallery-slides
    .image-gallery-slide
    .image-gallery-image
    > div:nth-child(2) {
    margin: 0.8rem;
  }
`;

const SliderWrapper = styled('div')``;

const badgesStyle = css`
  display: contents;

  > div > div > div {
    padding: 0.2rem 1rem;
    line-height: initial;
    font-weight: bold;
    font-size: 12px;
  }

  .black {
    background: black;
    color: white;

    &.outline {
      background: transparent;
      border: 1px solid black;
      color: black;
    }
  }

  .gold {
    background: ${theme.colors.gold};
    color: white;
    &.outline {
      background: transparent;
      border: 1px solid ${theme.colors.gold};
      color: ${theme.colors.gold};
    }
  }

  .white {
    background: white;
    color: black;
    &.outline {
      background: transparent;
      border: 1px solid white;
      color: white;
    }
  }
`;

export const StyledBadges = props => (
  <div className={badgesStyle}>
    <Badges {...props} />
  </div>
);

export default function ImageContainer({ images, badges, variant, ...rest }) {
  const galleryRef = React.createRef();
  // Enable switching gallery to image of selected variant
  useSwitchToVariantImage({
    galleryImages: images,
    galleryRef,
    variant
  });

  const selectedVariantImageIndex = variant
    ? images.findIndex(image => image.url === variant.images?.[0]?.url)
    : null;

  const imageSizes = [1, 1, 1, '300', '600'];

  return (
    <>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <div {...rest} className={cx(rest.className, wrapper)}>
              <LoadableGallery
                aspect={'1:1'}
                images={images}
                galleryRef={galleryRef}
                thumbnailPosition={'left'}
                backgroundColor="#F3F3F3"
                sizes={imageSizes}
                eagerlyLoad={
                  selectedVariantImageIndex
                    ? [0, selectedVariantImageIndex]
                    : [0]
                }
              >
                <StyledBadges badges={badges} />
              </LoadableGallery>
            </div>
          ) : (
            <SliderWrapper>
              <SharedSlider mobileSlides={1}>
                {images.map((image, index) => (
                  <Image
                    key={index}
                    src={image.url}
                    cover
                    critical={true}
                    sizes={imageSizes}
                    aspect={'1:1'}
                  />
                ))}
              </SharedSlider>
            </SliderWrapper>
          )
        }
      </Above>
    </>
  );
}
