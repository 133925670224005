import React, { useContext, useState, useEffect } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useScript } from '../hooks/useScript';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { RowTitle } from '../ContentEditor/RowTitle';

const LipscoreReview = styled('div')`
  padding: 3rem;

  width: 100%;
  background-color: #fff;
  ${theme.below.md} {
    padding: 2rem 0.75rem;
  }

  h2 {
    margin-bottom: 40px;
    ${theme.below.lg} {
      margin-bottom: 0;
    }
  }
`;

const LipscoreContent = styled('div')`
  width: 50%;
  margin: auto;
  .lipscore-testimonial-slider-date {
    margin-left: 30px;
    ${theme.below.lg} {
      margin-left: 10px;
    }
  }
  .lipscore-testimonial-slider-header {
    display: flex;
    justify-content: center;
  }
  .lipscore-service-review-testimonial {
    border: none;
  }
  .lipscore-testimonial-slider-slide:before {
    display: none;
  }
  .lipscore-testimonial-slider-text {
    text-align: center;
  }
  ${theme.below.lg} {
    width: 80%;
  }
  ${theme.below.md} {
    width: 100%;
  }
`;

export const LipscoreCompanyRating = ({ showCustomer = true }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const language = selectedChannel?.country?.code.toLowerCase();

  const [loaded, error] = useScript(
    '//static.lipscore.com/assets/' + language + '/lipscore-v1.js'
  );
  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (loaded) {
      if (typeof window !== 'undefined') {
        if (window.lipscore && inited) {
          window.lipscore.initWidgets();
        } else {
          window.lipscoreInit = function() {
            window.lipscore.init({
              apiKey: '3b1ebc36ad4d32ebe0e8d322'
            });
          };
          setInited(true);
        }
      }
    }
  }, [loaded, inited]);

  if (!loaded || error) return null;

  return (
    <>
      {showCustomer ? (
        <LipscoreReview>
          <LipscoreContent>
            <RowTitle title={'Anmeldelser'} />
            <div
              className="lipscore-service-review-testimonial"
              ls-widget-height="150px"
              ls-widget-width="100%"
            />
          </LipscoreContent>
        </LipscoreReview>
      ) : (
        <div
          className="lipscore-service-review-badge-starred"
          ls-widget-height="85px"
          ls-widget-width="85px"
        ></div>
      )}
    </>
  );
};
