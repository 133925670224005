import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image';
import { Below } from '@jetshop/ui/Breakpoints';

const FirstPostWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  ${theme.below.lg} {
    margin: 0 -16px;
    padding-bottom: 60px;
  }
  .no-post {
    text-align: center;
  }
`;

const FirstPostTextWrapper = styled('div')`
  display: flex;
  position: absolute;
  justify-content: flex-start;
  align-items: baseline;
  ${theme.below.lg} {
    position: relative;
  }

  > div {
    margin: 140px 100px;
    background: white;
    max-width: 50%;
    min-width: 300px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${theme.below.lg} {
      margin: auto;
      margin-top: -135px;
      padding: 30px;
      width: 90%;
    }
  }
  h3 {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 1px;
  }
  h2 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 1px;
    ${theme.below.lg} {
      font-size: 22px;
      line-height: 24px;
    }
  }

  .textwrapper {
    margin-bottom: 30px;
  }
`;

const FirstPostCTA = styled(Link)`
  background: ${theme.colors.gold};
  color: ${theme.colors.white};
  padding: 10px;
  text-decoration: none;
  text-align: center;
  width: 50%;

  ${theme.below.lg} {
    width: 40%;
    padding: 7px;
    font-size: 14px;
  }
`;

const PostWrapper = styled('div')`
  --postslength: ${props => props.postslength};
  width: calc(100% / var(--postslength));
  padding: 0 20px;
  ${theme.below.lg} {
    width: 100%;
    padding: 0;
  }

  &.list {
    width: calc(100% / 3);
    ${theme.below.lg} {
      width: 100%;
    }
  }
`;

const PostTextWrapper = styled('div')`
  padding: 8px 0 20px 0;
  text-align: center;
  h3 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
`;

const PostLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const FirstJournalPostCard = ({ post }) => {
  const imageSizes = [1 / 4, 1 / 2, 1];
  return (
    <>
      {post ? (
        <FirstPostWrapper key={post?.id}>
          <div>
            <Below breakpoint="lg">
              {matches =>
                matches ? (
                  <Image
                    cover
                    sizes={imageSizes}
                    aspect="375:500"
                    src={post?.data?.items[1]?.properties[2]?.value?.value}
                    critical
                  />
                ) : (
                  <Image
                    cover
                    sizes={imageSizes}
                    aspect="128:50"
                    src={post?.data?.items[1]?.properties[0]?.value?.value}
                    critical
                  />
                )
              }
            </Below>
          </div>
          <FirstPostTextWrapper>
            <div>
              <div className="textwrapper">
                <h3>{post?.parent?.name}</h3>
                <h2>{post?.name}</h2>
              </div>
              <FirstPostCTA to={`${post?.primaryRoute?.path}`}>
                Les mer
              </FirstPostCTA>
            </div>
          </FirstPostTextWrapper>
        </FirstPostWrapper>
      ) : (
        <FirstPostWrapper>
          <p className="no-post">Mangler innlegg</p>
        </FirstPostWrapper>
      )}
    </>
  );
};

export const JournalPostCard = ({ post, postslength, list, menu }) => {
  const imageSizes = [1 / 4, 1 / 3, 1 / 3];
  return (
    <>
      {post?.primaryRoute?.path && (
        <>
          <PostWrapper
            postslength={postslength}
            className={cx(list && 'list', menu && 'postwrapper')}
            key={post?.id}
          >
            <PostLink to={`${post?.primaryRoute?.path}`}>
              <Below breakpoint="lg">
                {matches =>
                  matches ? (
                    <Image
                      cover
                      sizes={imageSizes}
                      aspect="334:222"
                      src={post?.data?.items[1]?.properties[0]?.value?.value}
                    />
                  ) : (
                    <Image
                      cover
                      sizes={imageSizes}
                      aspect="413:275"
                      src={post?.data?.items[1]?.properties[0]?.value?.value}
                    />
                  )
                }
              </Below>
              <PostTextWrapper>
                <h3>{post?.name}</h3>
              </PostTextWrapper>
            </PostLink>
          </PostWrapper>
        </>
      )}
    </>
  );
};
