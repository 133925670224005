import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { filterChildrenByKey } from '../../utils/HelpFunctions';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';

const MembersClubWrapper = styled('div')`
  display: flex;
  align-items: center;
  max-width: 100vw;
  width: 80rem;
  margin: auto;
  padding: 0 40px;

  ${theme.below.lg} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 22px;
  }
`;

const TextWrapper = styled('div')`
  flex: 1;
  padding-left: 120px;
  max-width: 50%;

  > div {
    margin-top: 20px;
  }
  ${theme.below.lg} {
    padding-left: 0;
    max-width: 100%;
    margin-top: 30px;
  }
`;

const ImageWrapper = styled('div')`
  flex: 1;
  width: 100%;
`;

const ButtonRow = styled('div')`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  min-width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const MembersClub = ({
  desktopImage,
  desktopImageAspect,
  mobileImage,
  mobileImageAspect,
  title,
  html,
  children
}) => {
  const buttonChildren = filterChildrenByKey(children, 'button', true);
  const basicButtonChildren = filterChildrenByKey(
    children,
    'basicButton',
    true
  );

  return (
    <MembersClubWrapper>
      <ImageWrapper>
        <Above breakpoint="lg">
          {matches => (
            <Image
              src={matches ? desktopImage?.value : mobileImage?.value}
              alt={matches ? desktopImage?.alt : mobileImage?.alt}
              aspect={
                matches ? desktopImageAspect?.value : mobileImageAspect?.value
              }
            />
          )}
        </Above>
      </ImageWrapper>
      <TextWrapper>
        <h3>{title?.value}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: html?.value
          }}
        />
        <ButtonRow>
          {buttonChildren}
          {basicButtonChildren}
        </ButtonRow>
      </TextWrapper>
    </MembersClubWrapper>
  );
};
