import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image/Image';

const BoxPushWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.below.lg} {
    flex-direction: column;
  }
`;

const BoxPushItemWrapper = styled('div')`
  width: 100%;
  margin-right: 10px;
  max-width: calc(100% / 4);

  ${theme.below.lg} {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  [data-flight-image-children] {
    background-color: #00000030;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    ${theme.below.lg} {
      padding: 0;
    }
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  p {
    font-size: 18px;
    font-weight: 500;
  }

  .title {
    font-size: 24px;
  }
`;

export const BoxPush = ({ children }) => {
  return <BoxPushWrapper>{children}</BoxPushWrapper>;
};

export const BoxPushItem = ({ title, imageUrl, boxLink, textColor, text }) => {
  return (
    <BoxPushItemWrapper>
      <a
        href={boxLink?.value}
        textColor={textColor?.value}
        target="_blank"
        alt="link"
      >
        <Image src={imageUrl?.value} aspect="400:400" cover={true} crop={true}>
          <p>{text?.value}</p>
          <h3 className="title">{title?.value}</h3>
        </Image>
      </a>
    </BoxPushItemWrapper>
  );
};
