import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import CategoryContentQuery from '../../ContentEditor/CategoryContentQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { footerComponents } from '../../ContentEditor/ContentEditorComponents';
import { useInView } from 'react-intersection-observer';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { BasicButton } from '../../ContentEditor/BasicButton';
import { useLocation } from 'react-router';

const MembersClubWrapper = styled('div')`
  background: ${theme.colors.gold};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  margin: auto;
  padding: 60px 40px;
  height: 100%;
  color: white;

  ${theme.below.lg} {
    padding: 40px 0;
    .usp-wrapper {
      padding: 0 20px;
      flex-direction: column;
    }

    h2 {
      font-size: 22px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 16px;
  }

  .usp-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    li {
      padding-right: 20px;
      margin-left: 20px;
      list-style-type: disc;
    }
  }

  .button-wrapper {
    margin-top: 30px;
  }
`;

const MembersClubFooter = () => {
  return (
    <MembersClubWrapper>
      <div>
        <h2>BLI MEDLEM I VÅR KUNDEKLUBB</h2>
        <ul className="usp-wrapper">
          <li>15% bonus på alle ordinære varer </li>
          <li>Fri frakt over 199,- </li>
          <li>Digitale kvitteringer </li>
          <li>Eksklusive og personlige tilbud </li>
        </ul>
      </div>
      <div className="button-wrapper">
        <BasicButton
          title={'Bli med nå'}
          text={'BLI MED NÅ'}
          theme={'WHITE'}
          link={'/login'}
        />
      </div>
    </MembersClubWrapper>
  );
};

const Footer = () => {
  const categoryResult = useQuery(CategoryContentQuery, {
    variables: {
      id: 154
    }
  });

  const location = useLocation();

  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  return (
    <div ref={ref}>
      {location?.pathname.includes('my-pages') ||
      location?.pathname.includes('login') ||
      location?.pathname.includes('signup') ? null : (
        <MembersClubFooter />
      )}
      {categoryResult?.data?.category?.data?.items && (
        <ContentRenderer
          items={categoryResult?.data?.category?.data?.items}
          components={footerComponents}
        />
      )}
    </div>
  );
};

export default React.memo(Footer);
